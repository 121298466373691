@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Rajdhani:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-size: 18px;
  background-color: var(--color-butter-light);
  font-family: var(--font-serif);

  --font-serif: "Libre Baskerville", serif;
  --font-sans-serif: "Rajdhani", sans-serif;
  --color-purple: #25213e;
  --color-purple-light: #362f5e;
  --color-purple-dark: #161226;
  --color-butter: #f2dab5;
  --color-butter-light: #fbeed0;
  --color-yellow: #d98d00;
  --color-yellow-light: #fdcc00;
  --color-yellow-dark: #b16500;
  --color-red: #ff2f38;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: var(--color-butter-light);
}

::-webkit-scrollbar-thumb {
  background: var(--color-purple-dark);
  border: 4px solid var(--color-butter-light);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-purple-light);
}
