.faq {
  line-height: 1.75;
}

@media (width < 720px) {
  .faq {
    font-size: 16px;
  }
}

.faq dt {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.faq dd {
  margin-bottom: 2em;
}
