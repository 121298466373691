h2 {
  font-size: 42px;
  margin-block: 0.5em;
  text-align: center;
  font-family: var(--font-sans-serif);
  color: var(--color-purple);
  text-transform: uppercase;
}

h3 {
  font-size: 32px;
  margin-block: 0.5em;
  text-align: center;
  font-family: var(--font-sans-serif);
  color: var(--color-purple);
  text-transform: uppercase;
}

@media (width < 720px) {
  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 28px;
  }
}

section p {
  line-height: 1.75;
}

section p a {
  color: var(--color-purple-light);
  outline-color: var(--color-purple-light);
  outline-offset: 0.2em;
}

section p:not(:last-child) {
  margin-bottom: 1em;
}

@media (width < 720px) {
  section p {
    font-size: 16px;
  }
}

.section-wrapper {
  padding: 1em;
  padding-bottom: 3em;
}

.section-wrapper.dark {
  background-color: var(--color-butter);
}

.section-inner-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
